<template>
    <main class="free-index-page">
        <div class="free-index-page__cover">
            <div class="cover-content">
                <h1 class="cover-content__title">
                    {{ $lang.pages.gratis.products }}
                    <span class="text-violet-600">{{ $lang.pages.gratis.free }}</span>
                    {{ $lang.pages.gratis.in_mexico }}
                </h1>
            </div>
        </div>

        <div class="free-index-page-main">
            <main class="free-index-page-main__body">
                <div class="main-content">
                    <MiscTabs :info="tabsData" class="mb-3" />
                    <!-- MAIN CARDS -->
                    <ul v-show="tabsData.show === 0" class="space-y-3">
                        <li v-for="(item, key) in info.page.data.popular.data" :key="key" class="list-none">
                            <DCardMain :info="item" />
                        </li>
                    </ul>

                    <ul v-show="tabsData.show === 1" class="space-y-3">
                        <li v-for="(item, key) in info.page.data.latest.data" :key="key" class="list-none">
                            <DCardMain :info="item" />
                        </li>
                    </ul>
                </div>
                <ButtonLoadMore
                    v-if="pagination[getType].show"
                    class="mt-4"
                    :loading="pagination[getType].loading"
                    :handler="loadMore"
                />
            </main>
            <aside class="free-index-page-main__sidebar">
                <LayoutGallery
                    :path="`/${$lang.routes.brands}`"
                    :title="$lang.pages.gratis.featured_stores"
                    :info="stores"
                    :max-items="15"
                />
            </aside>
        </div>
    </main>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { $lang } = useNuxtApp()
const { SessionToken } = useAuthStore()
const RootStore = useRootStore()
const stores = computed(() => RootStore.layoutData?.data.sidebar.stores || [])
const getDiscounts = (page?: number) =>
    $fetch<Api.Responses.Pages.Free>(endpoints.pages.free.path, {
        headers: buildHeaders(SessionToken),
        baseURL,
        method: 'GET',
        params: {
            page,
        },
    })

const { data: responseData, error } = await useAsyncData('free-index', async () => await getDiscounts())

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let info = responseData.value

/* const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.cupones.home,
        },
        {
            path: `/${$lang.routes.coupons}`,
            title: $lang.pages.cupones.coupons,
        },
    ]
}) */

const pagination = reactive({
    popular: {
        loading: false,
        show: true,
    },
    latest: {
        loading: false,
        show: true,
    },
})

const tabsData = reactive({
    show: 0,
    tabs: [{ title: $lang.pages.cupones.populars }, { title: $lang.pages.cupones.news }],
})

const getType = computed(() => {
    return tabsData.show === 0 ? 'popular' : 'latest'
})

const loadMore = () => {
    pagination[getType.value].loading = true

    let next = 0

    const copyInfo = JSON.parse(JSON.stringify(info))

    if (tabsData.show === 0) {
        // Popular
        next = copyInfo.page.data.popular.meta.current_page + 1
        // Nuevos
    } else if (tabsData.show === 1) {
        next = copyInfo.page.data.latest.meta.current_page + 1
        // Comentados
    } else {
        next = copyInfo.page.data.most_comment.meta.current_page + 1
    }

    getDiscounts(next)
        .then((res) => {
            if (tabsData.show === 0) {
                // Populares
                copyInfo.page.data.popular.data.push(...res.page.data.popular.data)
                copyInfo.page.data.popular.meta = res.page.data.popular.meta
            } else {
                // Nuevos
                copyInfo.page.data.latest.data.push(...res.page.data.latest.data)
                copyInfo.page.data.latest.meta = res.page.data.latest.meta
            }

            info = copyInfo

            pagination[getType.value] = {
                loading: false,
                show: next < info.page.data[getType.value].meta.last_page,
            }
        })
        .catch((err: any) => {
            console.error(err)
            pagination[getType.value].loading = false
        })
}

const currentDates = currentDate()
const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [responseData.value.page.title || '', responseData.value.page.description || ''],
)

const Route = useRoute()

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.free-index-page {
    &__cover {
        @apply relative mb-6 h-52 overflow-hidden bg-hero-free bg-cover bg-no-repeat object-cover sm:h-48;
        .cover-background {
            @apply h-full bg-cover bg-center brightness-50 filter;
        }
        .cover-content {
            @apply container flex h-52 flex-col items-center justify-center gap-2 md:flex-row md:gap-6;
            &__title {
                @apply w-full text-center text-lg font-bold md:w-1/2 md:text-2xl;
            }
            &__img {
                @apply hidden w-32 md:block;
            }
        }
    }
    &-main {
        @apply container mb-3 xl:flex;

        &__body {
            @apply gap-y-6 xl:order-2 xl:flex-grow;
            .main-content {
                @apply rounded-2xl;
                h1 {
                    @apply text-xl font-semibold text-gray-800;
                }
            }
        }
        &__sidebar {
            @apply mt-4 space-y-5 lg:order-1 xl:mr-5 xl:mt-0 xl:w-80 xl:flex-none;
        }
    }
}
</style>
